export const func = {
    exceptionsHandler: (error) => {
        let response = error.response;
        let status = response.status ? response.status : 0;
        switch (status){
            case 413:
                window.getAlert('error', 'Ошибка', 'Слишком долгая загрузка файла, проверьте интернет и размер файла')
                break;
            default:
                response.statusText
                    ? window.getAlert('error', 'Ошибка ' + status, response.statusText)
                    : window.getAlert('error', 'Ошибка ' + status, error);
                break;
        }
    }
}